import React from 'react';

/* Images */
import logo from './logo.png';
import presentationImg from './images/presentation.jpg';
import presentationMobileImg from './images/presentation-mobile.jpg';
import iconWordPress from './images/icon-wordpress@2x.png';
import iconPrestashop from './images/icon-prestashop@2x.png';
import iconBootstrap from './images/icon-bootstrap@2x.png';
import iconPhp from './images/icon-php@2x.png';
import iconHtml5 from './images/icon-html5@2x.png';
import iconCss3 from './images/icon-css3@2x.png';
import iconWampserver from './images/icon-wampserver@2x.png';
import iconSymfony from './images/icon-symfony@2x.png';
import iconGit from './images/icon-git@2x.png';
import iconVscode from './images/icon-vscode@2px.png';
import iconMamp from './images/icon-mamp@2x.png';
import iconSql from './images/icon-sql@2x.png';
import iconJavascript from './images/icon-javascript@2x.png';
import iconJquery from './images/icon-jquery@2x.png';
import iconGtm from './images/icon-gtm@2x.png';
import iconGa from './images/icon-ga@2x.png';

/* PDF */
import cvCM from './pdf/cv-carine-merian.pdf';

/* CSS */
import './App.css';

/* Icons */
import GetAppIcon from '@material-ui/icons/GetApp';
import EmailIcon from '@material-ui/icons/Email';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MenuIcon from '@material-ui/icons/Menu';

/* Timeline */
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

/* Typewriter */
import Typewriter from "typewriter-effect";

/* Scroll */
import * as Scroll from 'react-scroll';
let LinkScroll = Scroll.Link;

/* Theme */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#adc546',
      dark: '#adc546',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial',
    h1: {
      fontFamily: 'Edo SZ',
    },
    h2: {
      fontFamily: 'Edo SZ',
    },
    h3: {
      fontFamily: 'Edo SZ',
    },
    h4: {
      fontFamily: 'Edo SZ'
    },
    h5: {
      fontFamily: 'Edo SZ'
    },
    h6: {
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: '1.15em',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexWrap: 'wrap',
    paddingLeft: '1px',
    paddingRight: '1px',
  },
  toolbarTitle: {
    flexGrow: 1,
    filter: 'brightness(0%)',
  },
  toolbarLogo: {
    maxWidth: '70px',
    height: 'auto',
  },
  link: {
    margin: theme.spacing(1, 1.5),
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
    color: theme.palette.common.black,
  },
  buttom: {
    marginRight: '15px',
  },
  profilImage: {
    maxWidth: '280px',
    height: 'auto',
    width: 'auto',
    border: '5px solid #adc546',
    borderRadius: '10px',
    boxShadow: '1px 1px 10px 1px #666',
  },
  profilImageMobile: {
    maxWidth: '60px',
    position: 'absolute',
    right: '10px',
    top: '-5px',
  },
  timeline: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  timelineConnector: {
    width: '1px',
    backgroundColor: '#adc546',
  }
}));


function App() {

  /* Classes */
  const classes = useStyles();

  /* Menu */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="sticky" color="default" elevation={0}>
        <Container component="div" maxWidth="md">
          <Toolbar className={classes.toolbar}>
            <Box py={1} className={classes.toolbarTitle}>
              <img width="70" height="59" src={logo} alt="Logo" className={classes.toolbarLogo} />
            </Box>
            <Hidden smDown>
              <nav>
                <LinkScroll to="presentation" spy={true} smooth={true} offset={-50} duration={500} variant="button" color="textPrimary" href="#presentation" className={classes.link}>
                  Présentation
                </LinkScroll>
                <LinkScroll to="competences" spy={true} smooth={true} offset={-50} duration={500} variant="button" color="textPrimary" href="#competences" className={classes.link}>
                  Compétences
                </LinkScroll>
                <LinkScroll to="experience" spy={true} smooth={true} offset={-50} duration={500} variant="button" color="textPrimary" href="#experience" className={classes.link}>
                  Expérience & formation
                </LinkScroll>
              </nav>
            </Hidden>
            <Hidden mdUp>
              <Button aria-label="Menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} variant="contained" color="primary" className={classes.link}>
                <MenuIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <LinkScroll onClick={handleClose} to="presentation" spy={true} smooth={true} offset={-50} duration={500} variant="button" color="textPrimary" href="#presentation" className={classes.link}>
                  Présentation
                </LinkScroll><br /><br />
                <LinkScroll onClick={handleClose} to="competences" spy={true} smooth={true} offset={-50} duration={500} variant="button" color="textPrimary" href="#competences" className={classes.link}>
                  Compétences
                </LinkScroll><br /><br />
                <LinkScroll onClick={handleClose} to="experience" spy={true} smooth={true} offset={-50} duration={500} variant="button" color="textPrimary" href="#experience" className={classes.link}>
                  Expérience & formation
                </LinkScroll>
              </Menu>
            </Hidden>
            <Button rel="noreferrer" aria-label="LinkedIn" href="https://www.linkedin.com/in/carine-m%C3%A9rian-8615ab8b" target="_blank" variant="contained" color="primary" className={classes.link}>
              <LinkedInIcon></LinkedInIcon>
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <main>
        <Box py={8} pt={8} id="presentation">
          <Container component="div" maxWidth="md">
            <Grid container alignItems="center">
              <Grid item md={6} sm={12}>
                <Box mb={2}>
                  <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
                    Carine Mérian<br />Développeur web
                  </Typography>
                </Box>
                <Hidden mdUp>
                  <Box position="relative">
                    <img src={presentationMobileImg} alt="Carine Mérian" className={`${classes.profilImage} ${classes.profilImageMobile}` } />
                  </Box>
                </Hidden>
                <br />
                <Typewriter
                  onInit={(typewriter)=> {
                    typewriter
                    .typeString("var <strong>identity</strong> = {")
                    .typeString("<br/><em>name</em>: <strong>'Carine Mérian'</strong>,")
                    .typeString("<br/><em>location</em>: <strong>'Lorient'</strong>,")
                    .typeString("<br/><em>date_of_birth</em>: <strong>'09/07/1992'</strong>,")
                    .typeString("<br/><em>skills</em>: [<strong>'WordPress'</strong>, <strong>'Prestashop'</strong>, <strong>'Symfony'</strong>],")
                    .typeString("<br/><em>hobbies</em>: [<strong>'Informatique'</strong>, <strong>'Randonnée'</strong>, <strong>'Musique'</strong>],")
                    .typeString("<br/>};")
                    .start();
                  }} 
                />
                <br /><br />
                <Button variant="contained" color="primary" href="mailto:carine.merian@gmail.com" className={classes.buttom} target="_blank" rel="norefferer" startIcon={<EmailIcon />}>
                  Me contacter
                </Button>
                <Button variant="contained" color="primary" href={cvCM} className={classes.buttom} target="_blank" rel="norefferer" startIcon={<GetAppIcon />}>
                  <Hidden smDown>
                    Télécharger&nbsp;
                  </Hidden>
                  mon CV
                </Button>
              </Grid>
              <Hidden smDown>
                <Grid item md={6} align="right">
                  <Box component="span" display="inline-block" width="auto">
                    <img src={presentationImg} alt="" width="281" height="291" className={classes.profilImage} />
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </Box>
        <Box pt={8} pb={8} id="competences" bgcolor="grey.100">
          <Container component="div" maxWidth="md" align="left">
            <Box mb={5}>
              <Typography component="h2" variant="h3" align="left" color="textPrimary" gutterBottom>
                Compétences
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={6} align="left">
                <Box component="div" mb={3} mr={2}>
                  <Typography component="h3" variant="h6" align="left" color="textPrimary" gutterBottom>
                    Technologies
                  </Typography>
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="HTML5" src={iconHtml5} />}
                    label="HTML5"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="CSS3" src={iconCss3} />}
                    label="CSS3"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="PHP" src={iconPhp} />}
                    label="PHP"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="JavaScript" src={iconJavascript} />}
                    label="JavaScript"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="SQL" src={iconSql} />}
                    label="SQL"
                  />
                </Box>
                <Box component="div" mb={3} mr={2}>
                  <Typography component="h3" variant="h6" align="left" color="textPrimary" gutterBottom>
                    Frameworks
                  </Typography>
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="Symfony" src={iconSymfony} />}
                    label="Symfony"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="Bootstrap" src={iconBootstrap} />}
                    label="Bootstrap"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="jQuery" src={iconJquery} />}
                    label="jQuery"
                  />
                </Box>
                <Box component="div" mb={3} mr={2}>
                  <Typography component="h3" variant="h6" align="left" color="textPrimary" gutterBottom>
                    CMS
                  </Typography>
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="WordPress" src={iconWordPress} />}
                    label="WordPress"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="Prestashop" src={iconPrestashop} />}
                    label="Prestashop"
                  />
                </Box>
              </Grid>
              <Grid item sm={6} align="left">
                <Box component="div" mb={3} mr={2}>
                  <Typography component="h3" variant="h6" align="left" color="textPrimary" gutterBottom>
                    Outils
                  </Typography>
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="Git" src={iconGit} />}
                    label="Git"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="VS Code" src={iconVscode} />}
                    label="VS Code"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="MAMP" src={iconMamp} />}
                    label="MAMP"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="WampServer" src={iconWampserver} />}
                    label="WampServer"
                  />
                </Box>
                <Box component="div" mb={3} mr={2}>
                  <Typography component="h3" variant="h6" align="left" color="textPrimary" gutterBottom>
                    Autre
                  </Typography>
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="Google Tag Manager" src={iconGtm} />}
                    label="Google Tag Manager"
                  />
                  <Chip
                    color="primary"
                    variant="outlined"
                    avatar={<Avatar alt="Google Analytics" src={iconGa} />}
                    label="Google Analytics"
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box id="experience" component="div" pt={8} pb={6}>
          <Container component="div" maxWidth="md">
            <Box mb={5}>
              <Typography component="h2" variant="h3" align="left" color="textPrimary" gutterBottom>
                Expérience & Formation
              </Typography>
            </Box>
            <Timeline className={classes.timeline}>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined">
                    <WorkIcon color="primary" />
                  </TimelineDot>
                  <TimelineConnector className={classes.timelineConnector} />
                </TimelineSeparator>
                <TimelineContent>
                  <Box py={.5} px={2}>
                    <Typography variant="h6" component="h3">
                      Développeur web - Lamour du Web
                    </Typography>
                    <Box py={2} fontStyle="italic" fontWeight="bold">
                        Mai 2016 – Aujourd'hui
                    </Box>
                    <Typography>
                      <strong>Wordpress</strong>: développement et intégration de thèmes sur-mesure avec <strong>Bootstrap</strong>.<br />
                      <strong>Prestashop</strong>: configuration du back-office et de modules, modification de thèmes et développement de petits modules.<br />
                      <strong>Symfony</strong> 4: développement d'un formulaire front-end connecté avec l'API Google Calendar.<br />
                      <strong>Shopify</strong>: modification de thèmes, création de sections.<br />
                      <strong>Support technique</strong>: maintenance des sites, traitement de tickets de support et demandes d'évolutions.
                    </Typography>
                    <Box py={3}>
                      <Chip
                        color="primary"
                        variant="outlined"
                        avatar={<Avatar alt="WordPress" src={iconWordPress} />}
                        label="WordPress"
                      />
                      <Chip
                        color="primary"
                        variant="outlined"
                        avatar={<Avatar alt="Prestashop" src={iconPrestashop} />}
                        label="Prestashop"
                      />
                    </Box>
                  </Box>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined">
                    <WorkIcon color="primary" />
                  </TimelineDot>
                  <TimelineConnector className={classes.timelineConnector} />
                </TimelineSeparator>
                <TimelineContent>
                  <Box py={.5} px={2}>
                    <Typography variant="h6" component="h3">
                      Développeur web - Markétik
                    </Typography>
                    <Box py={2} fontStyle="italic" fontWeight="bold">
                      Octobre 2012 – Janvier 2016
                    </Box>
                    <Typography>
                      Développement avec le framework <strong>Symfony</strong> 2 (front-end & back-end)<br />
                      Intégration <strong>HTML5</strong> / <strong>CSS3</strong> / <strong>Bootstrap</strong> / <strong>jQuery</strong>
                    </Typography>
                    <Box py={3}>
                      <Chip
                        color="primary"
                        variant="outlined"
                        avatar={<Avatar alt="Symfony" src={iconSymfony} />}
                        label="Symfony"
                      />
                    </Box>
                  </Box>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined">
                    <SchoolIcon color="primary" />
                  </TimelineDot>
                  <TimelineConnector className={classes.timelineConnector} />
                </TimelineSeparator>
                <TimelineContent>
                  <Box pb={3} pt={.5} px={2}>
                    <Typography variant="h6" component="h3">
                    BTS IG (Informatique de Gestion)
                    </Typography>
                    <Box py={2} fontStyle="italic" fontWeight="bold">
                      Septembre 2010 - Juin 2012
                    </Box>
                    <Typography>
                      Lycée Notre Dame de la Paix - Ploemeur
                    </Typography>
                  </Box>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined">
                    <SchoolIcon color="primary" />
                  </TimelineDot>
                  <TimelineConnector className={classes.timelineConnector}/>
                </TimelineSeparator>
                <TimelineContent>
                  <Box pb={3} pt={.5} px={2}>
                    <Typography variant="h6" component="h3">
                      Bac STG GSI (Gestion des Systèmes d’Information)
                    </Typography>
                    <Box py={2} fontStyle="italic" fontWeight="bold">
                      Septembre 2007 - Juin 2010
                    </Box>
                    <Typography>
                      Lycée Dupuy de Lôme - Lorient
                    </Typography>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Container>
        </Box>
        <Box border={5} borderColor="primary.main"></Box>
      </main>
    </MuiThemeProvider>
  );
}

export default App;